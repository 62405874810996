import * as React from 'react'
import * as Base from 'react-festival/build/press/list-content'
import { inject } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { PagePress, DocumentLink } from '@festival/entities/page-configuration'
import { Alert, Button, Card, CardTitle, Col, Row } from 'reactstrap'
import { RouteManager } from '@festival/route-manager'
import Manifest from '@festival/manifest'

export interface Props extends Base.Props {
    manifest?: Manifest
}

export class ListContent extends Base.ListContent<Props> {

    protected renderContent (details: PagePress, routeManager: RouteManager): React.ReactNode {
        const { manifest } = this.props

        if (!manifest) {
            return null
        }

        return (
            <div id="press">
                <Row>
                    <Col xl={6} className="border-right border-warning border-bottom">
                        { details.managerName && details.managerMail && (
                            <Card body className="mb-2 border-0 text-primary" id="pressManager">
                                <CardTitle tag="h3" className="text-uppercase">
                                   <i className="mdi mdi-pound ml-2"></i><FormattedMessage id="press.press-manager.title" />
                                </CardTitle>
                                <Row>
                                    <Col xs="auto">
                                        <img src={manifest.get('img/picto-infos.png')} className="picto" alt="Informations"/>
                                    </Col>
                                    <Col>
                                        <p>
                                           <FormattedMessage id="press.press-manager.content" />
                                        </p>
                                        <p>
                                            { details.managerName }<br />
                                            <a href={`mailto:${details.managerMail}`}>{ details.managerMail }</a>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </Col>
                    <Col xl={6} className="border-warning border-bottom">
                        <Card body className="mb-2 border-0 text-primary" id="pressAccreditation">
                            <CardTitle tag="h3" className="text-uppercase">
                                <i className="mdi mdi-pound ml-2"></i><FormattedMessage id="press.accreditation.title" />
                            </CardTitle>
                            <Row>
                                <Col xs="auto">
                                    <img src={manifest.get('img/picto-accreditation.png')} className="picto" alt="Informations"/>
                                </Col>
                                <Col>
                                    <p><FormattedMessage id="press.accreditation.content" /></p>
                                    <Button
                                        tag={Link}
                                        to={routeManager.generate('press-contact')}
                                        color="primary"
                                        >
                                        <FormattedMessage id="press.accreditation.link" />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} className="border-right border-warning border-bottom">
                        { details.medias && (
                            <Card body className="mb-2 border-0 text-primary">
                                <CardTitle tag="h3" className="text-uppercase">
                                    <i className="mdi mdi-pound ml-2"></i><FormattedMessage id="press.medias.title" />
                                </CardTitle>
                                <p><FormattedMessage id="press.medias.content" /></p>
                                { details.medias.length > 0 ? (
                                    <ul className="list-inline">
                                        { details.medias.map((document: DocumentLink, index: number) => (
                                            <li key={index} className="list-inline-item w-100 mb-2">
                                                <Button
                                                    tag="a"
                                                    href={document.available ? document.link : '#'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    color="primary"
                                                    block
                                                    disabled={!document.available || !document.link}
                                                    >
                                                    { document.title }
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Alert color="warning">
                                        <FormattedMessage id="press.medias.empty" />
                                    </Alert>
                                )}
                            </Card>
                        )}
                    </Col>
                    <Col xl={6}>
                        { details.pressReleases && (
                            <Card body className="mb-2 border-0 text-primary">
                                <CardTitle tag="h3" className="text-uppercase">
                                    <i className="mdi mdi-pound ml-2"></i><FormattedMessage id="press.press-releases.title" />
                                </CardTitle>
                                <p><FormattedMessage id="press.press-releases.content" /></p>
                                { details.pressReleases.length > 0 ? (
                                    <ul className="list-inline">
                                        { details.pressReleases.map((document: DocumentLink, index: number) => (
                                            <li key={index} className="list-inline-itemw-100 mb-2">
                                                <Button
                                                    tag="a"
                                                    href={document.available ? document.link : '#'}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    color="primary"
                                                    block
                                                    disabled={!document.available || !document.link}
                                                    >
                                                    { document.title }
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Alert color="warning">
                                        <FormattedMessage id="press.press-releases.empty" />
                                    </Alert>
                                )}
                            </Card>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default inject('pageConfigurationStore', 'routeManager', 'manifest')(ListContent)